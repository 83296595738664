import React from "react"
import { graphql } from "gatsby"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typegraphy from "@material-ui/core/Typography"

import ToolLayout from "../../../components/tool_layout"
import PipeWidget from "../../../components/widgets/pipe"
import { validate_url } from "../../../components/helpers/validator"

export const query = graphql`
  query UrlParseQuery {
    allToolsJson(filter: { path: { eq: "url/parse" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class UrlParseState {
  components: Map<string, any> = new Map()
}

class UrlParseResultWidget extends React.Component<{ value }> {
  state: UrlParseState

  constructor(props) {
    super(props)
    this.state = new UrlParseState()
  }

  getParseResultMap = (url: URL) => {
    let result = new Map<string, string>()

    result["protocol"] = url.protocol ? url.protocol.replace(":", "") : null
    result["host"] = url.host
    result["username"] = url.username
    result["password"] = url.password
    result["port"] = url.port

    result["pathname"] = url.pathname
    result["search"] = url.search.toString()
    result["hash"] = url.hash.toString()

    return result
  }

  parseUrl = (text: string) => {
    try {
      return new window.URL(text)
    } catch (e) {
      let url = new window.URL("http://" + this.props.value)
      url.protocol = ""
      return url
    }
  }

  render() {
    if (!this.props.value) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )
    }

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(
              this.getParseResultMap(this.parseUrl(this.props.value))
            ).map(entry => (
              <TableRow hover key={entry[0]}>
                <TableCell>
                  <Typegraphy variant="subtitle2">{entry[0]}</Typegraphy>
                </TableCell>
                <TableCell>{entry[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

class UrlParsePage extends React.Component<{ data: any }> {
  render() {
    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          outputComponent={UrlParseResultWidget}
          exceptionHandler={e => {
            if (e) {
              return "Invalid input"
            }
          }}
          inputProps={{
            validator: validate_url,
            placeHolderText: "Input URL here",
          }}
        />
      </ToolLayout>
    )
  }
}

export default UrlParsePage
